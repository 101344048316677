.cover {
    position: relative;

    &__image-wrapper {
        width: 100%;
        background-color: $color-bg--image;
        @include flex($justifyContent: flex-end);

        img {
            @include size(calc(50% + 335px), 680px);
            object-fit: cover;
            object-position: top;
        }
    }

    &__informations {
        background-color: $color-light;
        padding: 25px 0 50px;
        position: relative;
        z-index: 2;

        &:before, &:after {
            @include position(absolute, $top: -70px);
            @include size(50%, 70px);
            content: "";
            background-color: $color-light;
            z-index: 2;
        }

        &:before {
            left: 0;
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }

        &:after {
            right: 0;
            clip-path: polygon(0 100%, 100% 100%, 100% 0);
        }
    }

    &__title {
        @include flex($direction: column, $alignItems: center);
        gap: 15px;
        margin: 0 0 10px;
        text-align: center;

        svg {
            @include size(70px, 20px);
            fill: $color-main;
        }
    }

    p {
        font-family: $font-family;
        font-size: toRem(20);
        font-weight: 700;
        line-height: toRem(26);
        text-align: center;
    }

    &__intro {
        color: $color-text;
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .cover {
        &__image-wrapper img {
            @include size(calc(50% + 215px), 620px);
        }

        &__informations {
            &:before, &:after {
                height: 50px;
                top: -50px;
            }
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .cover {
        &__image-wrapper img {
            @include size(100%, 420px);
        }

        &__title {
            gap: 10px;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .cover {
        &__image-wrapper {
            height: 230px;
        }

        &__informations {
            &:before, &:after {
                display: none;
            }
        }

        &__title {
            gap: 5px;
            svg {
                width: 60px;
            }
        }

        p {
            font-size: toRem(16);
        }
    }
}
