//======================================================================================================
// SlideShow
//======================================================================================================
.home-slideshow {
    overflow: hidden;
    @include flex($justifyContent: flex-end);
    width: 100%;

    > .container {
        position: relative;
    }

    .container {
        &--content {
            position: relative;
            height: 100%;
        }

        &--pagination {
            @extend %removeMargin;
            @include position(absolute, $left: 770px, $bottom: 110px);
            width: fit-content;
            z-index: 2;
        }
    }

    .slideshow {
        @include size(calc(50% + 335px), 710px);
        margin: 0;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%);
            content: "";
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%);
            z-index: 2;
            transition: all $duration ease-in-out;
        }

        img, video {
            position: absolute;
            object-fit: cover;
            object-position: left;
            @include size(100%);
        }

        &__container {
            display: inherit;
            position: relative;
            @include size(100%);
        }

        &--video {
            img {
                display: none;
            }
        }

        // Pagination (bullet)
        &__pagination {
            // @include absolute($bottom: 110px, $left: calc(50% + 290px));
            @include flex($alignItems: center, $justifyContent: flex-end);
            min-width: 150px;
            gap: 20px;
            z-index: 2;
            width: auto;
    
            .swiper-pagination-bullet {
                @include size(15px);
                background-color: $color-white;
                border: 2px solid $color-white;
                border-radius: 50px;
                opacity: 1;
                transition: {
                    property: width, background-color;
                    duration: $duration;
                    timing-function: $timing;
                }
    
                &:hover, &:focus {
                    background-color: transparent;
                }
    
                &-active {
                    background-color: transparent;
                }
            }
        }
    
    }
}

//======================================================================================================
// Accès rapides
//======================================================================================================

.access {
    margin: 20px 0 82px;
    position: relative;

    &:before, &:after {
        @include position(absolute, $top: -124px);
        @include size(50%, 106px);
        content: "";
        background-color: $color-white;
        z-index: 2;
        transition: all $duration ease-in-out;
    }

    &:before {
        left: 0;
        clip-path: polygon(0 0, 0% 100%, 100% 100%);
    }

    &:after {
        right: 0;
        clip-path: polygon(0 100%, 100% 100%, 100% 0);
    }

    &__blocs {
        @include flex($justifyContent: space-between);
        position: relative;
        padding: 0 50px;
    }

    &__bloc {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        width: 100px;
        gap: 15px;
        position: relative;

        &:before {
            @include position(absolute, $bottom: 42px, $left: 50%);
            transform: translateX(-50%);
            @include size(0, 2px);
            content: "";
            background-color: $color-main;
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        svg {
            @include size(100px);
            z-index: 1;
            transition: all $duration ease-in-out;

            path {
                transition: all $duration ease-in-out;
            }
        }

        p {
            font-family: $font-family--heading;
            font-size: toRem(15);
            font-weight: 500;
            line-height: toRem(16);
            text-transform: uppercase;
            color: $color-dark;
            text-align: center;
        }

        &:hover, &:focus { 
            &:before {
                width: 40px;
            }

            svg {
                transform: translateY(5px);

                path {
                    &[fill="#E6BF65"] {
                        fill: $color-main;
                    }
    
                    &[fill="#25683B"] {
                        fill: $color-second;
                    }
                }
            }
        }
    }
}

//======================================================================================================
// Actualités & Agenda
//======================================================================================================

.front-page {
    &__sections {
        padding: 50px 0 125px;
        background-color: $color-light;
        position: relative;

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(100%);
            content: "";
            background-image: url(/wp-content/themes/cisse/assets/src/images/sprite-images/home/texture.png);
            background-repeat: repeat;
            z-index: 0;
            transition: all $duration ease-in-out;
        }
    }

    &__title {
        @extend %homeTitle;
        color: $color-dark;

        svg {
            fill: $color-main;
        }
    }

    &__content {
        margin-top: 35px;
        @include flex($justifyContent: space-between);
    }
}

//---------- Actus ----------//
.news {
    &__swiper {
        width: 800px;
        padding: 15px!important;
        margin: -15px!important;
    }

    &__link {
        height: 455px!important;
        box-shadow: 0px 5px 15px 0px rgba(40, 40, 40, 0.10);
        background-color: $color-white;
        position: relative;

        &:before, &:after {
            @include position(absolute, $top: 205px);
            @include size(50%, 20px);
            content: "";
            background-color: $color-white;
            z-index: 2;
        }
    
        &:before {
            left: 0;
            clip-path: polygon(0 0, 0% 100%, 100% 100%);
        }
    
        &:after {
            right: 0;
            clip-path: polygon(0 100%, 100% 100%, 100% 0);
        }

        &:hover, &:focus {
            .news__image {
                &:before, &:after {
                    height: 45px;
                    background-color: rgba($color-main, 0.7);
                }

                &:before {
                    clip-path: polygon(0 0,100% 50%,100% 100%,0 100%);
                }

                &:after {
                    clip-path: polygon(0 50%,100% 0,100% 100%,0 100%);
                }
            }

            .news__plus {
                margin-left: 10px;

                svg {
                    @extend %svgPlusHover;
                }
            }
        }
    }

    &__image {
        overflow: hidden;
        @include size(100%, 225px);
        position: relative;

        &:before, &:after {
            @include position(absolute, $bottom: 0);
            @include size(50%, 25px);
            content: "";
            background-color: $color-main;
            z-index: 1;
            transition: all $duration ease-in-out;
        }
    
        &:before {
            left: 0;
            clip-path: polygon(0 0, 100% 80%, 100% 100%, 0% 100%);
        }
    
        &:after {
            right: 0;
            clip-path: polygon(0 80%, 100% 0, 100% 100%, 0% 100%);
        }

        img {
            object-fit: cover;
            @include size(100%);
            transform: scale(1);
            transition: all $duration ease-in-out;
        }

        &--notimg {
            background: url('/wp-content/themes/noyau/assets/images/icon-post.png') no-repeat rgba($color-second, 0.55);
            background-position: center;
        }
    }

    &__content {
        padding: 20px 20px 25px;
    }

    &__title {
        @extend %homeTitleSmall;
    }

    &__introduction {
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(22);
        color: $color-dark;
        padding-top: 15px;
    }

    &__plus {
        @include position(absolute, $left: 20, $bottom: 25px);
        @include flex($alignItems: center);
        gap: 5px;
        font-family: $font-family--heading;
        font-size: toRem(15);
        font-weight: 500;
        line-height: toRem(16);
        text-transform: uppercase;
        color: $color-dark;
        transition: all $duration ease-in-out;
        
        svg {
            @extend %svgPlus;
        }
    }

    &__bottom {
        @include flex($alignItems: flex-end, $justifyContent: space-between);
        margin-top: 25px;
    }

    &__button {
        @extend %button;
        background-color: $color-main;
        color: $color-white;

        svg {
            fill: $color-white;
        }

        &:hover, &:focus {
            background-color: $color-second;
            border-color: $color-second;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }
        }
    }

    &__navigation {
        display: flex;
        gap: 10px;

        &--prev, &--next {
            @extend %navigation;
        }

        &.disable {
            display: none;
        }
    }
}

//---------- Agenda ----------//
.home-events {
    position: relative;

    &__list {
        @include flex($direction: column);
        gap: 15px;
    }

    &__link {
        box-shadow: 0px 5px 15px 0px rgba(40, 40, 40, 0.10);
    }

    &__linkcontainer {
        @include flex($alignItems: center);
        @include size(370px, 140px);
        background-color: $color-white;
        padding: 15px;
        gap: 25px;
        position: relative;
        overflow: hidden;

        &:before, &:after {
            @include position(absolute, $top: 0, $left: 0);
            content: "";
            background-color: $color-main;
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        &:before {
            clip-path: polygon(35% 0, 100% 50%, 35% 100%, 0 100%, 0 0);
            @include size(72px, 140px);
        }

        &:after {
            @include size(0, 100%);
        }

        &:hover, &:focus {
            &:before {
                left: 99%;
            }

            &:after {
                width: 100%;
            }

            .home-events__title {
                color: $color-white;
            }

            .home-events__plus {
                @extend %svgPlusHover;
            }
        }
    }

    &__date {
        @include flex($direction: column, $alignItems: center, $justifyContent: center);
        background-color: $color-second;
        color: $color-dark;
        padding: 10px 0;
        @include size(60px, fit-content);
        min-width: 60px;
        min-height: 70px;
        position: relative;
        z-index: 1;
        
        p {
            @include flex($direction: column, $alignItems: center);
            font-family: $font-family--heading;
            font-size: toRem(16);
            font-weight: 400;
            line-height: toRem(18);
            text-transform: uppercase;

            span {
                font-size: toRem(22);
                font-weight: 700;
                line-height: toRem(22);
            }
        }

        svg {
            @include size(18px);
        }
    }

    &__title {
        @extend %homeTitleSmall;
        position: relative;
        z-index: 1;
        transition: all $duration ease-in-out;
    }

    &__plus {
        @extend %svgPlus;
        @include position(absolute, $bottom: 10px, $right: 10px);
    }

    &__buttons {
        @include flex($justifyContent: flex-end);
        gap: 15px;
        margin-top: 30px;
    }

    &__button {
        @extend %button;

        &:first-child {
            background-color: $color-second;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-main;
                border-color: $color-main;
                color: $color-white;
    
                svg {
                    fill: $color-white;
                }
            }
        }

        &:last-child {
            background-color: transparent;
            border: 2px solid $color-second;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-second;
            }
        }
    }
}

//======================================================================================================
// Fil info
//======================================================================================================

.newsthread {
    margin-top: -50px;
    position: relative;
    z-index: 1;
    
    &__container {
        background-color: $color-white;
        box-shadow: 0px 5px 15px 0px rgba(40, 40, 40, 0.10);
    }

    &__content {
        @include flex($alignItems: center, $justifyContent: space-between);
        overflow: hidden;
        height: 100px;
        padding: 0 30px 0 25px;
        position: relative;

        &:before, &:after {
            content: "";
            z-index: 0;
            background-color: $color-main;
            transition: all 0.5s ease-in-out;
        }

        &:before {
            @include position(absolute, $top: 0, $left: 0);
            @include size(255px, 100%);
        }

        &:after {
            @include position(absolute, $top: 0, $left: 255px);
            @include size(20px, 100%);
            clip-path: polygon(0 0, 0% 100%, 100% 50%);
        }

        &.hover {
            &:before {
                width: 100%;
            }

            &:after {
                left: 1170px;
            }

            .newsthread__link {
                color: $color-white;
            }

            .newsthread__navigation {
                &--prev, &--next {
                    border-color: $color-second;

                    svg {
                        fill: $color-white;
                    }
                }
            }
        }
    }

    &__title {
        @include flex($alignItems: center);
        gap: 20px;
        font-family: $font-family--heading;
        font-size: toRem(34);
        font-weight: 400;
        line-height: toRem(40);
        text-transform: uppercase;
        color: $color-white;
        background-color: $color-main;
        position: relative;
        z-index: 1;

        &:before {
            @include position(absolute, $top: -24px, $right: -80px);
            @include size(45px, 100px);
            content: "";
            background-color: rgba($color-main, 0.2);
            clip-path: polygon(54% 0,100% 50%,54% 100%,0 100%,0 0);
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        svg {
            @include size(48px, 52px);
            fill: $color-second;
        }
    }

    &__swiper {
        @include size(570px, 100%);
        margin: 0 0 0 -60px!important;
    }

    &__link {
        @include flex($alignItems: center);
        font-family: $font-family;
        font-size: toRem(18);
        font-weight: 400;
        line-height: toRem(24);
        position: relative;
        z-index: 1;
        transition: color $duration ease-in-out!important;
    }

    &__navigation {
        display: flex;
        gap: 10px;
        position: relative;
        z-index: 1;

        &--prev, &--next {
            @extend %navigation;
        }

        &.disable {
            // display: none;
            pointer-events: none;
            opacity: 0.35;
        }
    }
}

//======================================================================================================
// Zoom sur 
//======================================================================================================

.zoom {
    margin: 80px 0 75px;

    &__title {
        @extend %homeTitle;
        color: $color-dark;

        svg {
            fill: $color-second;
        }
    }

    &__items {
        @include flex($alignItems: center, $justifyContent: space-between);
        margin-top: 35px
    }

    &__item {
        @include size(270px);
        overflow: hidden;
        position: relative;

        &:before, &:after {
            @include position(absolute, $bottom: 0, $left: 0);
            content: "";
            background-color: rgba($color-main, 0.85);
            z-index: 0;
            transition: all $duration ease-in-out;
        }

        &:before {
            @include size(100%, 90px);
            clip-path: polygon(50% 0, 100% 30%, 100% 100%, 0 100%, 0 30%);
        }

        &:after {
            @include size(100%, 0);
        }

        &--link {
            &:hover, &:focus {
                &:before {
                    bottom: 100%;
                }
    
                &:after {
                    height: 100%;
                }

                .zoom__plus {
                    bottom: 144px;
                    @extend %svgPlusHover;
                }

                .zoom__title--small {
                    bottom: 100px
                }
            }
        }
    }

    &__image {
        @include size(100%);
        object-fit: cover;
    }

    &__plus {
        @extend %svgPlus;
        @include position(absolute, $left: 50%, $bottom: 64px);
        transform: translateX(-50%);
        fill: $color-white;
        border-color: $color-white;
    }

    &__title--small {
        @extend %removeMargin;
        @include flex($direction: column, $justifyContent: center);
        gap: 5px;
        @include position(absolute, $bottom: 20px, $left: 50%);
        transform: translateX(-50%);
        @include size(90%, 38px);
        font-family: $font-family--heading;
        font-size: toRem(16);
        font-weight: 600;
        text-transform: uppercase;
        text-align: center;
        color: $color-white;
        z-index: 1;
        transition: all $duration ease-in-out;
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .home-slideshow {
        .container--pagination {
            left: 535px;
            bottom: 95px;
        }

        .slideshow {
            @include size(calc(50% + 215px), 620px);

            &__pagination {
                min-width: unset;
            }
        }
    }

    .access {
        margin: 30px 0 82px;
    
        &:before, &:after {
            top: -115px;
            height: 85px;
        }
    
        &:before {
            clip-path: polygon(0 0, 100% 85%, 100% 100%, 0% 100%);
        }
    
        &:after {
            clip-path: polygon(0 85%, 100% 0, 100% 100%, 0% 100%);
        }
    
        &__blocs {
            padding: 0 30px;
        }
    }

    .news {
        &__swiper {
            width: 650px;
        }
    
        &__link {
            &:before, &:after {
                height: 15px;
                top: 210px;
            }
    
            &:hover, &:focus {
                .news__image {
                    &:before, &:after {
                        height: 30px;
                        background-color: rgba($color-main, 0.7);
                    }
                }
            }
        }
    
        &__image {
            &:before, &:after {
                height: 20px;
            }
        }
    
        &__content {
            padding: 10px 20px 25px;
        }
    
        &__introduction {
            padding-top: 5px;
        }
    }

    .home-events {
        &__linkcontainer {
            width: 300px;
            gap: 15px;
        }
    
        &__buttons {
            @include flex($justifyContent: space-between);
            gap: 0;
        }

        &__button {
            padding: 0;

            &:first-child {
                padding: 0 15px 0 10px;
            }

            &:last-child {
                padding: 0 13px 0 8px;
            }
        }
    }

    .newsthread {
        &__content {
            &.hover {
                &:after {
                    left: 940px;
                }
            }
        }
    
        &__title {
            &:before {
                right: -70px;
            }
        }
    
        &__swiper {
            margin: 0 0 0 60px !important;
            width: 460px;
        }
    }

    .zoom {
        margin: 70px 0 50px;
    
        &__item {
            @include size(220px);
            
            &--link {
                &:hover, &:focus {
                    .zoom__plus {
                        bottom: 120px;
                    }
    
                    .zoom__title--small {
                        bottom: 75px;
                    }
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {

    .home-slideshow {
        position: relative;

        .container--pagination {
            left: 50%;
            bottom: 35px;
            transform: translateX(-50%);
        }

        .slideshow {
            @include size(100%, 420px);

            &--video {
                video {
                    display: none;
                }

                img {
                    display: block;
                }
            }
        }
    }

    .access {
        margin: 20px 0 60px;
    
        &:before, &:after {
            height: 50px;
            top: -70px;
            z-index: 1;
        }
    
        &__blocs {
           flex-wrap: wrap;
           justify-content: center;
           padding: 0 10px;
           gap: 20px;
        }
    
        &__bloc {
            &:before {
                bottom: 40px;
            }

            svg {
                @include size(80px);
            }
        }
    }

    .front-page {
        &__sections {
            padding: 50px 0 110px;
        }
    
        &__content {
            flex-direction: column;
            gap: 60px;
        }
    }

    .news {
        &__swiper {
            width: calc(100% + 20px);
            margin: -10px !important;
            padding: 10px !important;
        }

        &__link {
            box-shadow: 0 5px 12px 0 rgba(40,40,40,.1);
        }
    }

    .home-events {
        &__linkcontainer {
            width: 100%;
        }

        &__buttons {
            gap: 8px;
            justify-content: flex-end;
        }
    }

    .newsthread {
        &__container {
            position: relative;
        }

        &__content {
            flex-direction: column;
            align-items: flex-start;
            height: 200px;
    
            &:before {
               @include size(100%, 100px);
            }
    
            &:after {
                display: none;
            }
    
            &.hover {
                .newsthread__link {
                    color: $color-dark;
                }
            }
        }
    
        &__title {
            min-height: 100px;

            &:before {
                display: none;
            }
        }
    
        &__swiper {
            width: 100%!important;
            margin: 0 13px !important;
        }

        &__link {
            text-align: center;
        }
    
        &__navigation {
            @include position(absolute, $top: 30px, $right: 30px);
            display: flex;
            gap: 10px;
            z-index: 1;
    
            &--prev, &--next {
                @extend %navigation;
                border-color: $color-second;

                svg {
                    fill: $color-white;
                }
            }
        }
    }

    .zoom {
        &__items {
            margin-top: 30px;
            flex-wrap: wrap;
            gap: 20px 0;
        }
    
        &__item {
            @include size(calc(50% - 10px), 320px);
    
            &--link {
                &:hover, &:focus {
                    .zoom__plus {
                        bottom: 160px;

                    }
    
                    .zoom__title--small {
                        bottom: 110px;
                    }
                }
            }
        }
    }
}


// 640
@media screen and (max-width: $small) {
    
    .home-slideshow {
        .container--pagination {
            bottom: 20px;
        }

        .slideshow {
            height: 230px;
        }
    }

    .access {
        margin: 30px 0 60px;

        &:before, &:after {
            display: none;
        }
    }

    .newsthread {
        &__content {
            padding: 0 15px;
        }

        &__title {
            gap: 5px;

            svg {
                @include size(33px, 35.75px);
            }
        }

        &__swiper {
            // width: calc(100% - 40px) !important;
            // margin: 0 auto !important;
            width: 100% !important;
            margin: 0!important;
        }

        &__navigation {
            right: 25px;
        }
    }

    .zoom {
        margin: 80px 0 60px;

        &__items {
            margin-top: 30px;
            flex-wrap: wrap;
            gap: 20px 0;
        }
    
        &__item {
            @include size(100%, 280px);
    
            &--link {
                &:hover, &:focus {
                    .zoom__plus {
                        bottom: 145px;

                    }
    
                    .zoom__title--small {
                        bottom: 95px;
                    }
                }
            }
        }

        &__title--small {
            max-width: 200px;
        }
    }
}
