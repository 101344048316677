.footer {
    padding: 200px 0 40px;
    background-color: $color-third;
    color: $color-white;
    position: relative;

    &:before, &:after {
        @include position(absolute, $top: -2px);
        @include size(50%, 110px);
        content: "";
        background-color: $color-white;
        z-index: 2;
        transition: all $duration ease-in-out;
    }

    &:before {
        left: 1px;
        clip-path: polygon(0 0, 100% 100%, 100% 0);
    }

    &:after {
        right: 0;
        clip-path: polygon(0 0, 0% 100%, 100% 0);
    }

    &__container {
        position: relative;

        &:before {
            @include position(absolute, $top: -240px, $left: -240px);
            content: "";
            @include size(575px, 540px);
            background-image: url(/wp-content/themes/cisse/assets/src/images/sprite-images/Pictos/filigranne_footer.svg);
            background-repeat: no-repeat;
            z-index: 0;
            transition: all $duration ease-in-out;
            pointer-events: none;
        }
    }

    &__carte {
        @include position(absolute, $bottom: 75px, $right: 17px);
        @include size(144px);
        z-index: 2;
        transition: all $duration ease-in-out;

        img {
            @include size(100%);
        }

        &:hover, &:focus {
            transform: scale(1.075);
        }
    }

    &__content {
        display: flex;
        position: relative;

        &:before {
            @include position(absolute, $right: -617px, $top: -200px);
            content: "";
            @include size(1265px, 620px);
            background-image: url(/wp-content/themes/cisse/assets/src/images/sprite-images/home/carte.jpg);
            background-repeat: no-repeat;
            z-index: 0;
            transition: all $duration ease-in-out;
            pointer-events: none;
        }
    }

    &__coordonnees {
        width: 190px;
        margin-right: 80px;

        .footer__text {
            margin-bottom: 22px;
        }
    }

    &__horaires {
        width: 290px;
    }

    &__title {
        font-family: $font-family--heading;
        font-size: toRem(22);
        font-weight: 700;
        line-height: toRem(24)
    }

    &__text {
        @include flex($direction: column);
        font-family: $font-family;
        font-size: toRem(16);
        font-weight: 400;
        line-height: toRem(22);
        margin-top: 15px;

        span {
            text-transform: uppercase;
        }
    }

    &__phone {
        @include flex($alignItems: center);
        font-family: $font-family--heading;
        font-size: toRem(15);
        font-weight: 500;
        line-height: toRem(16);
        text-transform: uppercase;
        gap: 5px;
        transition: color $duration ease-in-out;

        svg {
            fill: $color-white;
            @include size(22px);
            transition: fill $duration ease-in-out;
        }

        &:hover, &:focus {
            color: $color-second;

            svg {
                fill: $color-second;
            }
        }
    }

    &__button {
        @extend %button;
        margin-top: 35px;
        padding: 0;

        &--contact {
            background-color: $color-second;
            color: $color-dark;

            svg {
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-light;
                border-color: $color-light;
                color: $color-dark;
            }
        }

        &--facebook {
            border: 2px solid $color-second;
            color: $color-white;
            width: 265px;

            svg {
                fill: $color-white;
            }

            &:hover, &:focus {
                background-color: $color-second;
                color: $color-dark;

                svg {
                    fill: $color-dark;
                }
            }
        }
    }

    &__menu {
        padding: 100px 0 0;
        @include flex($justifyContent: space-between, $alignItems: flex-end);
        position: relative;

        ul {
            @include flex($wrap: wrap, $alignItems: center);
            width: 500px;
            gap: 10px 30px;
        }

        li {
            position: relative;
            font-family: $font-family;
            font-size: toRem(12);
            font-weight: 400;
            line-height: toRem(22);
            text-transform: uppercase;

            a {
                &:hover, &:focus {
                    color: $color-second;
                }
            } 

            &::before {
                content: '';
                @include absolute($top: 50%, $right: -15px);
                @include size(1px, 12px);
                background-color: $color-white;
                transform: translateY(-50%);
            }

            &:last-child::before {
                background-color :transparent;
            }
        }
    }

    &__partenaires {
        @include flex($alignItems: flex-end);
        gap: 25px;
        margin-bottom: -5px;
    }

    &__partenaire {
        transition: all $duration ease-in-out;

        &:hover, &:focus {
            transform: scale(0.9);
        }
    }
}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .footer {
        padding: 180px 0 40px;
    
        &:before, &:after {
            height: 80px;
        }
    
        &__container {
            &:before {
                left: -212px;
                top: -222px;
            }
        }

        &__carte {
            bottom: 80px;
            right: 32px;
        }
    
        &__content {
            &:before {
                right: -765px;
                top: -180px;
            }
        }
    
        &__coordonnees {
            margin-right: 40px;
        }
    
        &__menu {
            padding: 90px 0 0;
    
            ul {
                width: 340px;
            }
        }
    
        &__partenaires {
            gap: 15px;
            margin-bottom: 8px;
        }
    }
}

// 960
@media screen and (max-width: $medium) {
    .footer {
        padding: 130px 0 50px;

        &:before, &:after {
            height: 50px;
        }
    
        &__container {
            position: relative;
    
            &:before {
                left: -195px;
                top: -170px;
            }
        }

        &__carte {
            bottom: 0;
            right: 15px;
        }
    
        &__content {
            &:before {
                display: none;
            }
        }
    
        &__coordonnees {
            width: 50%;
            margin-right: 0;
        }
    
        &__horaires {
            width: 50%;
            margin-left: 20px;
        }

        &__button {
            margin-top: 25px;

            &--contact {
                width: 190px;
            }
        }
    
        &__menu {
            padding: 70px 0 0;
            margin: 0;
            flex-direction: column-reverse;
            align-items: flex-start;
            max-width: unset;
    
            ul {
                width: 460px;
                margin-top: 30px;
            }
        }
    
        &__partenaires {
            align-items: center;
        }
    }
}

// 640
@media screen and (max-width: $small) {
    .footer {
        padding: 110px 0 55px;

        &:before, &:after {
            height: 20px;
        }

        &__container {
            &:before {
                left: -185px;
                top: -150px;
            }
        }

        &__carte {
            top: 475px;
            right: unset;
            left: 50%;
            transform: translateX(-50%);

            &:hover, &:focus {
                transform: translateX(-50%);
            }
        }

        &__content {
            flex-direction: column;
            align-items: center;
            gap: 40px;
        }

        &__coordonnees {
            @include flex($direction: column, $alignItems: center);
            width: 190px;
        }
    
        &__horaires {
            @include flex($direction: column, $alignItems: center);
            width: 290px;
            margin-left: 0;
        }

        &__title, &__text {
            text-align: center;
        }

        &__menu {
            padding: 215px 0 0;

            ul {
                margin-top: 18px;
                width: 100%;
                justify-content: center;
            }
        }

        &__partenaires {
            width: 100%;
            flex-wrap: wrap;
            justify-content: center;
            gap: 8px 15px;
        }
    }
}