//=================================================================================================
// Home
//=================================================================================================

// Usage : @extend %homeTitle;
%homeTitle {
    @extend %removeMargin;
    font-family: $font-family--heading;
    font-size: toRem(50);
    font-weight: 300;
    line-height: toRem(55);
    text-transform: uppercase;
    @include flex($direction: column, $alignItems: center);
    gap: 10px;

    svg {
        @include size(70px, 20px);
    }
}

// Usage : @extend %homeTitleSmall;
%homeTitleSmall {
    @extend %removeMargin;
    color: $color-dark;
    font-family: $font-family--heading;
    font-size: toRem(22);
    font-weight: 700;
    line-height: toRem(24);
}

// Usage : @extend %button;
%button {
    min-height: 45px;
    position: relative;
    @include flex($alignItems: center, $justifyContent: center);
    gap: 5px;
    font-family: $font-family--heading;
    padding: 0 20px 0 15px;
    font-size: toRem(15);
    font-weight: 600;
    line-height: toRem(20);
    text-transform: uppercase;
    text-align: center;
    transition: all $duration ease-in-out;

    svg {
        @include size(28px);
        transition: all $duration ease-in-out;
    }
}

// Usage : @extend %navigation;
%navigation {
    @include size(40px!important);
    @include position(relative!important, $top: unset!important, $left: unset!important, $bottom: unset!important, $right:unset!important);
    margin-top: 0!important;
    background-color: transparent;
    border: 2px solid $color-main;
    transition: all $duration ease-in-out;
    cursor: pointer;
    z-index: 2!important;

    &:after {
        display: none;
    }

    svg {
        fill: $color-dark;
        @include size(100%);
        transition: all $duration ease-in-out;
    }

    &:hover, &:focus {
        background-color: $color-main;

        svg {
            fill: $color-white;
        }
    }
}

// Usage : @extend %svgPlus;
%svgPlus {
    @include size(16px);
    border: 1px solid $color-dark;
    border-radius: 50px;
    z-index: 1;
    transition: all $duration ease-in-out;
}

// Usage : @extend %svgPlusHover;
%svgPlusHover {
    border-color: $color-second;
    background-color: $color-second;
    fill: $color-dark;
}

// Usage : @extend %removeMargin;
%removeMargin {
    margin: 0;
    padding: 0;
}

//=================================================================================================
// Clearfix
//=================================================================================================

// Usage : @extend %clearfix;
%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: " ";
        display: table;
    }

    &:after {
        clear: both;
    }
}


//=================================================================================================
// Visually hide an element
//=================================================================================================

// Usage : @extend %visuallyhidden
%visuallyhidden {
    width: 1px;
    height: 1px;
    position: absolute;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    margin: -1px;
    white-space: nowrap;
}


//=================================================================================================
// Centered block
//=================================================================================================

// Usage : @extend %center
%center {
    display: block;
    margin-left: auto;
    margin-right: auto; 
}


//=================================================================================================
// Centered container
//=================================================================================================

// Usage : @extend %container
%container {
    max-width: $large;
    padding: 0 ($gl-gutter * 0.5);
    margin: 0 auto;
}


//=================================================================================================
// Cacul for positionning element left or right container
//=================================================================================================

// Usage : @extend %SpaceFull-to-Container--left
%SpaceFull-to-Container--left {
    left: calc((100% - ($large - $gl-gutter))/2);
}

// Usage : @extend %SpaceFull-to-Container--right
%SpaceFull-to-Container--right {
    right: calc((100% - ($large - $gl-gutter))/2);
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    %container {
        max-width: $medium;
        padding: 0 ($gl-gutter-md * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - ($medium - $gl-gutter-md))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - ($medium - $gl-gutter-md))/2);
    }
}


// 960
@media screen and (max-width: $medium) {

    %container {
        max-width: 90%;
        padding: 0 ($gl-gutter-sm * 0.5);
        margin: 0 auto;
    }

    %SpaceFull-to-Container--left {
        left: calc((100% - (90% - $gl-gutter-sm))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - (90% - $gl-gutter-sm))/2);
    }
}


// 640
@media screen and (max-width: $small) {

    %container {
        padding: 0 ($gl-gutter-xs * 0.5);
        margin: 0 auto;
    }
    
    %SpaceFull-to-Container--left {
        left: calc((100% - (90% - $gl-gutter-xs))/2);
    }

    %SpaceFull-to-Container--right {
        right: calc((100% - (90% - $gl-gutter-xs))/2);
    }
}
