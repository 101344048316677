//======================================================================================================
// Bloc Album
//======================================================================================================
.bloc-album {
    position: relative;
    margin: 30px 0;

    .swiper-container {
        width: calc(100% - (70px * 2));
        height: 640px;

        .swiper-slide {
            @include flex($justifyContent: center, $alignItems: center);
            font-size: $font-size--5;
            text-align: center;

            & > figure,
            & > div {
                @include flex($justifyContent: center);
                @include size(100%);

                a {
                    display: flex;
                    @include size(100%);

                    img {
                        object-fit: cover;
                        @include size(100%);
                    }
                }

                .legende {
                    @include absolute($bottom: 15px);
                    display: flex;
                    max-width: 70%;
                    padding: 10px 40px;
                    background-color: $color-bg-caption;
                    font-size: $font-size--text;
                    color: $color-caption; 
                }
            }
        }

        &[data-captions="false"] {
            .swiper-slide {
                & > figure,
                & > div {
                    .legende {
                        display: none;
                    }
                }
            }
        }

        &[data-sizes-full="1"],
        &[data-sizes-square="1"] {
            .swiper-slide {
                & > figure,
                & > div {
                    a {
                        img {
                            object-fit: contain;
                            object-position: 50% 50%;
                        }
                    }
                }
            }
        }

        .swiper-button-next:after,
        .swiper-button-prev:after {
            color: $color-main;
        }
    }

    &--basic {
        .swiper-container {

            .swiper-slide {
                & > figure,
                & > div {
                    position: relative;
                }
            }

            &[data-scroll_direction="vertical"] {
                .swiper-button-next,
                .swiper-button-prev {
                    left: 50%;
                    transform: translateX(-50%) rotate(90deg);
                }

                .swiper-button-next {
                    top: initial;
                    bottom: 5px;
                }

                .swiper-button-prev {
                    top: 25px;
                    transform: rotate(90deg);
                }
            }

        }
    }

    &--multiple_rows {
        .swiper-container {
            .swiper-wrapper {
                @include flex($alignItems: center);
                @include size(100%, 640px);

                .swiper-slide {
                    @include flex($justifyContent: center, $alignItems: center);
                    height: calc(100% / 2);
                    font-size: $font-size--5;
                    text-align: center;
                }
            }

            .swiper-lazy-preloader {
                display: none;
            }

            &[data-margins="true"] {
                .swiper-wrapper {
                    .swiper-slide {
                        height: calc((100% - 30px) / 2);
                    }
                }
            }
        }

    }

    &--coverflow {
        .swiper-container {
            height: auto;

            .swiper-wrapper {
                align-items: center;

                .swiper-slide {
                    @include size(370px);

                    .swiper-slide-shadow-left,
                    .swiper-slide-shadow-right {
                        background-image: none;
                    }
                }
            }

            .swiper-lazy-preloader {
                display: none;
            }

            &[data-sizes-square="1"] {
                .swiper-wrapper {
                    .swiper-slide {
                        width: auto;
                        max-width: 370px;
                        max-height: 370px;
                    }
                }
            }
        }
    }

    &--flip {
        .swiper-container {
            .swiper-lazy-preloader {
                display: none;
            }
        }
    }

    &--gallery {
        height: auto;

        .swiper-button-next, .swiper-button-prev {
            top: calc((640px / 2) + ($arrow-size / 2));
        }

        .swiper-container {
            &:not(.gallery-thumbs) {
                width: calc(100% - (70px * 2));
                height: 640px;
            }

            &.gallery-thumbs {
                max-width: 100%;
                height: auto;
                box-sizing: border-box;
                padding: 10px 0;

                .swiper-wrapper {
                    @include flex($wrap: wrap);
                    width: 100%;
                    transform: none;

                    .swiper-slide {
                        flex: 1;
                        max-width: calc((100%) / 12);
                        min-width: calc((100%) / 12);
                        height: auto;
                        max-height: 75px;
                        min-height: 70px;
                        margin-bottom: 0;
                        margin-right: 0;
                        opacity: .4;
                        background-size: cover;
                        background-position: 50%;
                        transition: opacity $duration $timing;

                        &:hover, &:focus {
                            cursor: pointer;
                            opacity: 1 !important;
                        }

                        &.swiper-slide-thumb-active {
                            opacity: 1 !important;
                        }
                    }
                }
            }

            &[data-margins="true"] {
                & ~ .gallery-thumbs {
                    max-width: 100%;
                    height: auto;
                    box-sizing: border-box;
                    padding: 10px 0;

                    .swiper-wrapper {
                        @include flex($wrap: wrap);
                        width: 100%;
                        transform: none;

                        .swiper-slide {
                            flex: 1;
                            max-width: calc((100% - 120px) / 12);
                            min-width: calc((100% - 120px) / 12);
                            height: auto;
                            min-height: 70px;
                            opacity: .4;
                            background-size: cover;
                            background-position: 50%;
                            margin-bottom: 10px;
                            // margin-right: 10px;
                        }

                        &.swiper-slide-visible {
                            &:last-child,
                            &:nth-child(12n) {
                                margin-right: 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: flex;
        z-index: 2;
        @include size($arrow-size);
        background-color: $arrow-bg;
        border: 2px solid $color-second;
        border-radius: $btn-spe-border-radius;
        transition: background-color $duration $timing;
        text-align: center;

        svg {
            display: none;
        }

        &::after {
            position: relative;
            font-size: $font-size--text;
            font-weight: $font-weight-bold;
            color: $arrow-color; 
            transition: color $duration $timing;  
        }

        &:hover,
        &:focus {
            background-color: $arrow-bg--hover;
            
            &::after {
                color: $arrow-color--hover;
            }
        }
    }

    .swiper-button-prev {
        left: 0;

        &::after {
            left: -1px;
        }
    }

    .swiper-button-next {
        right: 0;

        &::after {
            right: -1px;
        }
    }

}

body.glightbox-open {
    height: initial !important;
}

#glightbox-body.glightbox-clean .gprev,
#glightbox-body.glightbox-clean .gnext,
#glightbox-body.glightbox-modern .gprev,
#glightbox-body.glightbox-modern .gnext {
    display: flex !important;
    justify-content: center;
    align-items: center;
    @include size(auto);
    margin: 0;
    padding: 0;
    background-color: $color-light;
    border-radius: $arrow-border-radius;
    opacity: 1;
    transition: background-color $duration $timing;

    svg {
        @include size($arrow-size);
        fill: $arrow-color;
        transition: fill $duration $timing;

        path {
            fill: inherit;
        }
    }

    &:hover,
    &:focus {
        background-color: $arrow-bg--hover;

        svg {
            fill: $arrow-color--hover;
        }
    }
}

#glightbox-body.glightbox-clean .gclose,
#glightbox-body.glightbox-modern .gclose {
    display: flex !important;
    justify-content: center;
    align-items: center;
    @include size(auto);
    margin: 0;
    padding: 0;
    background-color: $cross-bg;
    border-radius: $cross-border-radius;
    opacity: 1;
    transition: background-color $duration $timing;

    svg {
        @include size($cross-size);
        fill: $cross-color;
        transition: fill $duration $timing;

        path {
            fill: inherit;
        }
    }

    &:hover,
    &:focus {
        background-color: $cross-bg--hover;

        svg {
            fill: $cross-color--hover;
        }
    }
}

.glightbox-clean .gdesc-inner,
.glightbox-modern .gdesc-inner {
    padding: 0;

    .gslide-title {
        @include absolute ($bottom: 15px, $left: 50%);
        display: flex;
        max-width: 70%;
        margin-bottom: 0;
        padding: 10px 40px;
        background-color: $color-bg-caption;
        font-family: $font-family;
        font-weight: $font-weight;
        font-size: $font-size;
        line-height: $line-height;
        color: $color-caption;
        transform: translate(-50%);
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {

    .bloc-album {
        .swiper-container {
            height: 520px;
        }

        &--multiple_rows {
            .swiper-container {
                .swiper-wrapper {
                    height: 520px;
                }
            }
        }

        &--coverflow {
            .swiper-container {
                height: auto;

                .swiper-wrapper {
                    .swiper-slide {
                        @include size(300px);
                    }
                }

                &[data-sizes-square="1"] {
                    .swiper-wrapper {
                        .swiper-slide {
                            width: auto;
                            max-width: 300px;
                            max-height: 300px;
                        }
                    }
                }
            }
        }

        &--gallery {

            .swiper-button-next, .swiper-button-prev {
                top: calc((520px / 2) + ($arrow-size / 2));
            }

            .swiper-container {
                &:not(.gallery-thumbs) {
                    height: 520px;
                }

                &.gallery-thumbs {
                    .swiper-wrapper {
                        .swiper-slide {
                            min-width: calc((100%) / 9);
                            max-width: calc((100%) / 9);
                        }
                    }
                }

                &[data-margins="true"] {
                    & ~ .gallery-thumbs {    
                        .swiper-wrapper {    
                            .swiper-slide {
                                max-width: calc((100% - (10px * 9)) / 9);
                                min-width: calc((100% - (10px * 9)) / 9);
                            }
                        }
                    }
                }
            }
        }
    }

}


// 960
@media screen and (max-width: $medium) {

    .bloc-album {
        .swiper-container {
            @include size(100%, 390px);
            margin-bottom: 90px;
        }

        .swiper-button-prev, .swiper-button-next {
            top: calc(100% + 40px);
        }

        .swiper-button-prev {
            left: calc(50% - 45px);
        }

        .swiper-button-next {
            right: calc(50% - 45px);
        }

        &--coverflow {
            .swiper-container {
                height: auto;
            }
        }

        &--multiple_slides {
            .swiper-container {
                height: 550px;
            }
        }

        &--multiple_rows {
            .swiper-container {
                .swiper-wrapper {
                    height: 400px;
                }
            }
        }

        &--coverflow {
            .swiper-container {
                .swiper-wrapper {
                    .swiper-slide {
                        @include size(230px);
                    }
                }

                &[data-sizes-square="1"] {
                    .swiper-wrapper {
                        .swiper-slide {
                            width: auto;
                            max-width: 230px;
                            max-height: 230px;
                        }
                    }
                }
            }
        }

        &--gallery {
            .swiper-container:not(.gallery-thumbs) {
                @include size(100%, 385px);
                margin-bottom: 60px;
            }

            .swiper-button-prev, .swiper-button-next {
                top: calc(385px + $arrow-size);
            }

            .swiper-container {
                &.gallery-thumbs {
                      .swiper-wrapper {
                        .swiper-slide {
                            min-width: calc((100%) / 7);
                            max-width: calc((100%) / 7);
                        }
                    }
                }

                &[data-margins="true"] {
                    & ~ .gallery-thumbs {    
                        .swiper-wrapper {    
                            .swiper-slide {
                                max-width: calc((100% - (10px * 7)) / 7);
                                min-width: calc((100% - (10px * 7)) / 7);
                            }
                        }
                    }
                }
            }
        }
    }

}


// 640
@media screen and (max-width: $small) {

    
    .bloc-album {
        .swiper-container {
            height: 200px;
        }

        &--multiple_rows {
            .swiper-container {
                height: 400px;
            }
        }

        &--coverflow {
            .swiper-container {
                height: auto;

                .swiper-wrapper {
                    .swiper-slide {
                        @include size(180px);
                    }
                }

                &[data-sizes-square="1"] {
                    .swiper-wrapper {
                        .swiper-slide {
                            width: auto;
                            max-width: 180px;
                            max-height: 180px;
                        }
                    }
                }
            }
        }

        &--gallery {
            .swiper-container:not(.gallery-thumbs) {
                @include size(100%, 180px);
                margin-bottom: 60px;
            }

            .swiper-button-prev, .swiper-button-next {
                top: calc(180px + $arrow-size);
            }

            .swiper-container {
                &.gallery-thumbs {
                    .swiper-wrapper {
                        .swiper-slide {
                            min-width: calc((100%) / 3);
                            max-width: calc((100%) / 3);
                        }
                    }
                }

                &[data-margins="true"] {
                    & ~ .gallery-thumbs {    
                        .swiper-wrapper {    
                            .swiper-slide {
                                max-width: calc((100% - (10px * 3)) / 3);
                                min-width: calc((100% - (10px * 3)) / 3);
                            }
                        }
                    }
                }
            }
        }
    }

}
