.header {
    @include size(unset, fit-content);
    background-color: rgba($color-third, 0);
    @include position(absolute, $top: 0, $left: 0);
    width: 100%;

    &__container {
        @include flex($justifyContent: space-between);
    }

    &__content {
        position: relative;
        z-index: 2;

        &:before {
            @include position(absolute, $top: 0, $left: -375px);
            @include size(770px, 710px);
            content: "";
            background-color: $color-third;
            clip-path: polygon(85% 0,100% 41%,82% 100%,0 100%,0 0);
            z-index: -1;
        }
    }

    &__link {
        display: flex;
        height: fit-content;
        margin-top: 70px;

        &--logofixe {
            display: none;
        }
    }

    &__title {
        font-size: 0;
        line-height: 0;
        @include size(0);
    }

    &--fixed {
        @include position(fixed, $top: 0, $left: 0, $bottom: 0, $right:0);
        background-color: rgba($color-third, 0.95);
        z-index: 10;
        animation: slide-bottom 0.5s ease-in-out 0s 1 normal forwards;

        @keyframes slide-bottom {
            from {
                top: -100px;
            }
        
            to {
                top: 0;
            }
        }

        .header {
            &__container {
                height: 80px;
            }

            &__content {
               @include flex($alignItems: center);
               gap: 210px;

                &:before {
                    display: none;
                }
            }

            &__link {
                margin-top: 0;

                &--logo {
                    display: none;
                }
        
                &--logofixe {
                    display: flex;
                }
            }
        }

        .tools {
            margin-top: 16px;

            &__item {
                &:hover, &:focus {
                    background-color: $color-light;
                    border-color: $color-light;
                    color: $color-dark;

                    svg {
                        fill: $color-dark;
                    }
                }
            }
        }

        .nav-main {
            position: unset;

            &__button {
                position: relative;
                @include flex($alignItems: center, $justifyContent: center);
                column-gap: 5px;
                @include size(120px, 45px);
                padding: 0;
                margin: -3px 0 0;
                background-color: transparent;
                border: 2px solid $color-second;
                cursor: pointer;

                &::after {
                    content: "Menu";
                    font-family: $font-family--heading;
                    font-size: toRem(15);
                    font-weight: 500;
                    line-height: toRem(16);
                    color: $color-white;
                    text-transform: uppercase;
                    transition: all $duration ease-in-out;
                }

                &:hover, &:focus {
                    background-color: $color-second;
                    
                    &:after {
                        color: $color-dark;
                    }
        
                    .nav-main__icon {
                        background-color: $color-dark;

                        &:before, 
                        &:after {
                            background-color: $color-dark;
                        }
                    }
                }
            }
    
            // Button icon (Burger menu responsive - Open menu)
            &__icon {
                position: relative;
                display: block;
                width: 12px;
                height: 2px;
                background-color: $color-white;
                transition: all $duration ease-in-out;
    
                &:before, 
                &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: $color-white;
                    transition: all $duration ease-in-out;
                }
    
                &:before {
                    top: 5px;
                }
    
                &:after {
                    top: -5px;
                } 
            }

            &__container {
                display: none;
                left: 0;
                position: absolute;
                top: 80px;
                margin: 0 auto;
                padding: 70px 0 60px;
                width: 100vw;
                background-color: rgba(#093618, 0.9);
                transition: opacity $duration ease-in-out;

                &:before, &:after {
                    @include position(absolute, $top: 0);
                    content: "";
                    @include size(550px, 100%);
                    background-color: rgba(#093618, 0.9);
                    clip-path: unset;
                    z-index: 0;
                    transition: 0s;
                }

                &:before {
                    left: 100%;
                }

                &:after {
                    right: 100%;
                }
            }

            &.js-open-menu {
                .nav-main {
                    &__container {
                        display: flex;
                        
                        &:before {
                            left: 100%;
                        }
                    }

                    &__button {
                        &::after {
                            content: "Fermer";
                        }
                    }

                    // Burger menu Button icon
                    &__icon {
                        transform: rotateZ(-45deg);
                        top: 1px;
        
                        &::before, &::after {
                            width: 10px;
                            left: 1px;
                            transform: rotateZ(90deg);
                        }
            
                        &::before {
                            top: -1px;
                        }
            
                        &::after {
                            top: 1px;
                        }
                    }
                }

                .menu__link {
                    animation: opacity 1.2s ease-in-out 0s 1 normal forwards;

                    @keyframes opacity {
                        from {
                            opacity: 0;
                        }
                    
                        to {
                            opacity: 1;
                        }
                    }
                }
            }

            .menu {
                gap: 30px;
            }

            .submenu {
                top: 40px;

                &__close {
                    display: none;
                }
            }
        }

        &.js-open-menu {
            background-color: $color-third;
        }
    }
}

//======================================================================================================
// Tools
//======================================================================================================
.tools {
    display: flex;
    gap: 20px;
    margin-top: 25px;
    height: fit-content;
    position: relative;
    z-index: 2;
    
    #accessconfig {
        height: 45px
    }

    &__item {
        @extend %button;
        color: $color-dark;
        border-color: $color-white;
        background-color: $color-white;
        padding: 0 20px 0 15px!important;
        margin: 0!important;

        svg {
            @include size(28px);
            display: flex;
            fill: $color-dark;
            transition: all $duration ease-in-out;
        }

        &::before,
        &::after {
            background-color: $color-white;
        }

        &:hover,
        &:focus {
            background-color: $color-main;
            border-color: $color-main;
            color: $color-white;

            svg {
                fill: $color-white;
            }
        }

        &--illiwap {
            background-color: $color-second;
        }

        &--search--disabled {
            pointer-events: none;
            opacity: .5;
        }
    }

    &__translate {
        position: relative;

        &__wrapper {
            @include absolute($left: 50%, $top: 64px);
            display: none;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            gap: 5px;
            width: max-content;
            padding: 15px 20px 30px;
            background-color: $color-main;
            border-radius: $border-radius;
            transform: translateX(-50%);
        
            &.js-open {
                display: flex;
            }

            &::before {
                content: '';
                @include absolute($top: -6px, $left: 50%);
                display: block;
                @include size(30px);
                background-color: $color-main;
                border-radius: 5px;
                transform: translateX(-50%) rotate(45deg);
                pointer-events: none;
                z-index: -1;
            }

            a.glink {
                position: relative;
                font-family: $font-family--heading;
                font-size: toRem(16);
                font-weight: 400;
                line-height: toRem(20);
                color: $color-white;
        
                &:hover, &:focus {
                    color: $color-second;
                    text-decoration: underline;
                }
        
                &.gt-current-lang {
                    color: $color-second;
                    text-decoration: underline;
                }
            }
        }

        &__close {
            @include absolute($bottom: -10px, $left: 50%);
            @include flex($alignItems: center, $justifyContent: center);
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            background-color: $color-second;
            border-radius: $border-radius--round;
            border: none;

            svg {
                @include size(26px);
                fill: $color-dark;
            }

            &:hover, &:focus {
                background-color: $color-third;

                svg {
                    fill: $color-white;
                }
            }
        }

        a.glink.gt-current-lang {
            font-weight: normal;
        }
    }

}

//======================================================================================================
// Responsive
//======================================================================================================

// 1200
@media screen and (max-width: $large) {
    .header {
        &__link {
            margin-top: 50px;

            svg {
                @include size(220px, 150px);
            }
        }

        &__content {
            &:before {
                @include size(492px, 620px);
                left: -130px;
                clip-path: polygon(79% 0,100% 41%,76% 100%,0 100%,0 0);
            }
        }

        &--fixed {
            .header {
                &__content {
                    gap: 347px;
                }
    
                &__link {
                    &--logofixe {
                        @include size(125px, 50px);
                    }
                }
            }
        }
    }

    .tools {
        &__item {
            padding: 0!important;
            width: 45px;

            span {
                display: none;
            }

            &--illiwap {
                width: fit-content;
                padding: 0 20px 0 15px !important;

                span {
                    display: flex;
                }
            }
        }
    }
}


// 960
@media screen and (max-width: $medium) {
    .header {
        position: relative;
        background-color: $color-third;

        &:before {
            @include position(absolute, $bottom: -20px, $left: 0);
            @include size(100%, 20px);
            content: "";
            background-color: $color-third;
            clip-path: polygon(50% 100%, 0 0, 100% 0);
            z-index: 2;
        }

        &__container {
            height: 80px;
        }

        &__content {
            @include flex($justifyContent: space-between, $alignItems: center);
            width: 100%;
            margin-right: 2px;
            z-index: 3;

            &:before {
                display: none;
            }
        }

        &__link {
            margin-top: -5px;

            &--logo {
                display: none;
            }
    
            &--logofixe {
                display: flex;
                @include size(125px!important, 50px!important);
            }
        }
    }

    .tools {
        margin-top: 17px;
        gap: 10px;
        z-index: 2;

        &__item--illiwap {
            padding: 0 10px 0 5px !important;
        }
    }
}


// 640
@media screen and (max-width: $small) {
    .header {
        &__container {
            height: 135px;
            justify-content: center;
            gap: 6px;
        }

        &__content {
            position: unset;
            @include size(fit-content);
            z-index: unset;
        }

        &__link {
            @include position(absolute, $bottom: 10px, $left: 50%);
            transform: translateX(-50%);
            margin-top: 0;
        }
    }

    .tools {
        margin-top: 15px;
        gap: 8px;

        &__item {
            @include size(40px);
            min-height: unset;

            &--illiwap {
                width: fit-content;
            }
        }
    }
}
