.post_navigation {
    // @include flex($justifyContent: center);
    padding: 40px 0 0;
    background-color: $color-bg--neutral;

    .navigation_link {
        @include flex($alignItems: center);
        text-transform: uppercase;
        font-family: $font-family--heading;
        font-size: toRem(15);
        font-weight: 500;
        line-height: toRem(16);
        color: $color-dark;
        background-color: $color-light;
        min-height: 45px;
        padding: 0 20px 0 15px;
        transition: color $duration $timing;
        transition: all $duration ease-in-out;

        svg {
            @include size(26px);
            fill: $color-dark;
            transition: fill $duration $timing;
        }

        &:hover, &:focus {
            background-color: $color-second;
        }
    }
}

// Update Upgrades 11/2023
// for all buttons navigations
.navigation_link {
    column-gap: 5px;
}

.post_navigation {
    &--container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        gap: 30px;
    }

    .return {
        a {
            align-items: center;
        }
    }

    .prev, .next {
      // A adapter en fonction de la width du bouton précédent
      min-width: 148px;

        .navigation_link {
            pointer-events: none;
            color: $color-text;
            background-color: $color-bg--transparent;

            // Recup style ~ ligne 9
            a {
                pointer-events: auto;
                display: flex;
                align-items: center;
                text-transform: uppercase;
                font-family: $font-family--heading;
                font-size: toRem(15);
                font-weight: 500;
                line-height: toRem(16);
                color: $color-text;
                background-color: $color-light;
                min-height: 45px;
                padding: 0 20px 0 15px;
                transition: all $duration ease-in-out;
            }
        }
    }

    .prev {
        .navigation_link {
          display: flex;
          justify-content: flex-start;
          padding: 0;

            a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-text;
                    mask-image: url( $urlShort + '/ic-nav-arrow-left.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-right: 5px;
                }
            }
        }
    }

    .next {
        .navigation_link {
            display: flex;
            justify-content: flex-end;
            padding: 0;

          a {
                &:after {
                    content: "";
                    display: inline-block;
                    width: 26px;
                    height: 26px;
                    background-color: $color-text;
                    mask-image: url( $urlShort + '/ic-nav-arrow-right.svg');
                    mask-repeat: no-repeat;
                    transition: background-color 0.25s;
                    margin-left: 5px;
                }
            }
        }
    }

    .prev, .next {
        &:hover, &:focus, &:focus-within {

            .navigation_link {
                background-color: $color-bg--transparent;

                svg {
                    fill: $color-text;
                }

                a {
                    background-color: $color-second;
                    &:before, &:after {
                        background-color: $color-text;
                    }
                }
            }
        }
    }
}


//======================================================================================================
// Responsive
//======================================================================================================

// 960
@media screen and (max-width: $medium) {

    .post_navigation {
        padding: 30px 0;
        
        &--container {
            gap: 20px 0;
            justify-content: space-between;
        }

        .prev, .next {
            // A changer en fonction de la taille du bouton "suivant"
            min-width: 125px;
        }
    }
}


// 640
@media screen and (max-width: $small) {

    .post_navigation {

        .prev, .next {
            min-width: auto;
        }

        .return {
            display: flex;
            justify-content: center;
            order: 1;
            flex: 1 1 100%;
        }
    }
}